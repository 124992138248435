import { Controller } from "stimulus";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.bootstrap5.css";

export default class extends Controller {
  static values = {
    options: Object
  };

  static defaultOptions = {
    maxItems: 1,
    maxOptions: null,
    openOnFocus: false,
    copyClassesToDropdown: false
  };

  connect() {
    this.element.classList.add("search-select");

    this.options = Object.assign(
      {},
      this.optionsValue,
      this.constructor.defaultOptions
    );

    this.initSelect();
  }

  initSelect() {
    this.select = new TomSelect(this.element, this.options);

    this.select.on("type", query => {
      if (!query.length) {
        this.select.clear();
        this.select.close();
      }
    });

    this.select.on("focus", () => {
      const value = this.select.getValue();

      if (value) {
        const text = this.select.options[value].text;
        this.select.setTextboxValue(text);
        this.select.open();
      }
    });
  }
}
