import { Controller } from "stimulus";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.bootstrap5.css";

export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      plugins: ["remove_button"],
      maxOptions: 6,
      onItemAdd: function() {
        this.setTextboxValue("");
        this.refreshOptions();
      },
      searchField: ["text", "code"],
      render: {
        option: function(data, escape) {
          return `<div>
                    <span class="code badge">${escape(data.code)}</span>
                    <span>${escape(data.text)}</span>
                  </div>`;
        },
        item: function(data, escape) {
          return `<div>
                    <span class="code badge">${escape(data.code)}</span>
                    <span>${escape(data.text)}</span>
                  </div>`;
        }
      }
    });
  }
}
