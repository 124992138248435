// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["form", "output", "source"]

  connect() {
    Rails.fire(this.formTarget, "submit")
  }

  createSuccess(event) {
    const [_data, _status, xhr] = event.detail

    this.outputTarget.innerHTML = xhr.response
  }

  copy() {
    this.sourceTarget.select()
    document.execCommand("copy")
  }
}
