import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "human", "raw" ]
  toggle(event) {
    event.preventDefault();

    this.rawTarget.classList.toggle("stimulus-hide");
  }
}
